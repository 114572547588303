import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "メドレーが協賛させていただいた「MANABIYA」に行って来ました！",
  "date": "2018-04-11T06:50:00.000Z",
  "slug": "entry/2018/04/11/155000",
  "tags": ["medley"],
  "hero": "./2018_04_11.png",
  "heroAlt": "manabiya"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`皆さんこんにちは。開発本部の日下です。普段は`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`オンライン診療アプリ「CLINICS」`}</a>{`および`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`オンライン医療事典「MEDLEY」`}</a>{`の開発を担当しています。（昨年、新卒で昨年メドレーに入ったのでエンジニア歴は 1 年弱。ベテランが多いメドレーのエンジニアチームで奮闘しています）`}</p>
    <p>{`先日開催された`}<a parentName="p" {...{
        "href": "https://teratail.com/"
      }}>{`エンジニア特化型 Q&A サイト「`}</a><a parentName="p" {...{
        "href": "https://teratail.com/"
      }}>{`teratail`}</a><a parentName="p" {...{
        "href": "https://teratail.com/"
      }}>{`」`}</a>{`さんのイベント、`}<a parentName="p" {...{
        "href": "https://manabiya.tech/"
      }}>{`MANABIYA`}</a>{`にメドレーがスポンサーとして協力させていただきました。
エンジニアの平木と参加しましたので、イベント当日の様子をレポートします。`}</p>
    <h1>{`MANABIYA とは`}</h1>
    <p>{`teratail はご存知の方も多いかもしれませんが、MANABIYA というイベントをご存じない方もいるかもしれません。
MANABIYA は今回が初開催のイベントで、”疑問” を日本中から集め、カンファレンスを通じて解決策を見出し、”知恵” を生み出すことを目的とし、秋葉原から近い`}<a parentName="p" {...{
        "href": "https://www.3331.jp/"
      }}>{`3331 Arts Chiyoda`}</a>{`にて 2 日間に渡って開催されました。`}</p>
    <h1>{`全体の雰囲気`}</h1>
    <undefined><img {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180411/20180411115536.png",
        "alt": "20180411115536.png"
      }}></img>{`
全体的に初開催とは思えないほど人が多く、一部のセッションでは教室が満杯になり、入場制限がかかるほどでした。また、speaker として豪華なメンバー面々が数多く集まっており、最初から最後まで、参加したすべてのセッションで立ち見が出てるほど盛況だったのがとても印象的でした。`}</undefined>
    <p>{`また、イベントの名前にもなっているように、学び舎というイメージがぴったりのコンセプトで統一されているのも特徴的でした。
会場自体も元々中学校だったものを利用したアートギャラリーを使用しており、教室や階段など、元を活かした作りになっていたのでイベントの雰囲気ととてもマッチしていました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180411/20180411160342.png",
      "alt": "20180411160342.png"
    }}></img>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "元々教室だったところでセッションが行われました"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180411/20180411115957.jpg",
        "alt": "20180411115957.jpg"
      }}></img><figcaption parentName="figure">{`元々教室だったところでセッションが行われました`}</figcaption></figure>
    <h1>{`セッション`}</h1>
    <p>{`セッションは Web、インフラ、DB、プログラミングから、AI、IoT 等の最近話題になっている分野まで幅広く開かれていました。`}</p>
    <p>{`我々が参加した 2 日目はプログラミング、Web、IoT のセッションが主に開催されていました。`}</p>
    <p>{`メドレーのプロダクトはすべて Web サービスとして提供されていることもあり、私は、Web 関連のセッションを中心に、時間が空いたら他の分野のセッションもふらっと覗き見るような形で参加しました。`}</p>
    <p>{`エンジニアとして成長する上で必要な戦略の話や、Service Worker や Web Payments など最近環境が整ってきた技術をプロダクトに組み込んでみた話など、幅広く見ることができました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "入場制限状態でやっと入れたものの立ち見の方の後ろから見ることに"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180411/20180411120051.jpg",
        "alt": "20180411120051.jpg"
      }}></img><figcaption parentName="figure">{`入場制限状態でやっと入れたものの立ち見の方の後ろから見ることに`}</figcaption></figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "運良く前の方の席が取れましたが、こちらも立ち見の方がたくさんいらっしゃいました"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180411/20180411120136.jpg",
        "alt": "20180411120136.jpg"
      }}></img><figcaption parentName="figure">{`運良く前の方の席が取れましたが、こちらも立ち見の方がたくさんいらっしゃいました`}</figcaption></figure>
    <p>{`個人的に古川氏による「`}<strong parentName="p">{`Web Application 2018 From Performance Perspective`}</strong>{`」は特に勉強になったセッションでした。
Web サイトのパフォーマンスに関してのエッセンスを詰めたようなセッションで、その歴史と共に、その時々のベストプラクティス、そしてその時代のバイブルをまとめ、今どういった背景でどういった対策をするべきか、その指針となるような話聞くことができました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "サイトパフォーマンスの歴史の概要を説明していただきました"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180411/20180411120434.png",
        "alt": "20180411120434.png"
      }}></img><figcaption parentName="figure">{`サイトパフォーマンスの歴史の概要を説明していただきました`}</figcaption></figure>
    <p>{`特に、Web 側は SPA として動いている CLINICS を開発しているため、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Server rendered pages are not optional`}</code>{` という言葉の意味が衝撃的で、帰る途中からずっとそのあたりのことを考えてしまうほどでした。
Speaker Deck にスライドが上がっていますので、気になる方はそちらをご参照ください。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/4336cbe6b51b4fa4af2dbe3dabd244f9" title="Web Application 2018 From Performance Perspective" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "420px"
    }} data-ratio="1.3333333333333333"></iframe>
    <p>{`Web アプリケーションのパフォーマンスに関することは個人的に気になっていたものの、どこから手を付けてよいのかわからず理解があまり進んでない分野だったため、必要な知識を 40 分という短い時間で把握することができたのは非常にありがたかったです。`}</p>
    <p>{`まさにイベントの趣旨である”気になっていること”、「回答」を知りたいがなかなか ”知る機会がなかったこと” という ”疑問” を、カンファレンスを通じて解決策を見出し、”知恵” を生み出す体験ができたのではないかと思っています。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "リアル版 teratail も登場"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180411/20180411120233.jpg",
        "alt": "20180411120233.jpg"
      }}></img><figcaption parentName="figure">{`リアル版 teratail も登場`}</figcaption></figure>
    <h1>{`まとめ`}</h1>
    <p>{`大規模かつ幅広い分野を取り扱っており、自分の専門や興味のある分野以外も同時に開催されたため、気軽に参加することができるイベントでした。
初めてエンジニアリングに触れて 1 年弱が経ち、その間に業務などを通じて学んできた過程で言語化できていなかったことが思っていた以上にあったことを、このイベントを通じて改めて知ることができました。`}</p>
    <p>{`こういった大規模なイベントは初参加だったのですが、多くのことが知れたのでとても良かったです。
次回開催される際は、また行ってみようと思っています。`}</p>
    <p>{`イベントでメドレーのことを知りご興味持っていただいた方は、お気軽にご連絡ください！
どんなエンジニア・デザイナーが働いているか、どんなことをやっているか等、ぜひ HP をご覧ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.wantedly.com/feed/s/medley-int"
      }}>{`https://www.wantedly.com/feed/s/medley-int`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      